
import { reactive } from 'vue'

export default {
	components: { },
	setup(props, context) {
		return {
			options: reactive({}),
			setting: reactive({}),
		}
	},
	mounted() {
		//获取数据
		this._post('/Infos/api', {}, (res)=>{
			this.setting = res.data
			this.$forceUpdate()
			console.log(this.setting)
		})
	},
	methods: {
		
	}	
}
